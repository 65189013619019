import './FormComponent.scss';

import { useState, useEffect } from 'react';
import API from '@aws-amplify/api';
import Checkbox from 'react-simple-checkbox';

import { submitRegisterForm } from '../graphql/mutations';
import { validateEmail } from '../shared/helpers';
import { AutocompleteComponent } from './AutocompleteComponent';
import { EVENT_TYPES } from '../App';

const APP_VERSION = '1.1.3';

const LambdaErrorKeys = {
  EmailExists: 'EmailExists',
};

const FormElements = {
  FIRST_NAME: 'FIRST_NAME',
  LAST_NAME: 'LAST_NAME',
  EMAIL : 'EMAIL',
  COMPANY: 'COMPANY',
  CONSENT: 'CONSENT',
}

export const FormComponent = ({ submitCallback }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [consent, setConsent] = useState(false);

  const [ongoingCall, setOngoingCall] = useState(false);
  const [error, setError] = useState(null);
  const [invalidFormElements, setInvalidFormElements] = useState([]);

  const warmLambda = () => {

    API.graphql({
      query: submitRegisterForm,
      variables: {
        payload: {
          warming: true,
          token: '',
          company: '',
          firstname: '',
          lastname: '',
          email_address: '',
        }
      },
      authMode: 'API_KEY',
    });
  };

  useEffect(
    () => {
      warmLambda();
      console.log(APP_VERSION);
    },
    [],
  );

  useEffect(
    () => {
      if (ongoingCall) {
        return;
      }
      window.grecaptcha.ready(() => {
        window.grecaptcha.render('recaptcha-container', { sitekey: process.env.REACT_APP_CAPTCHA_KEY });
      });
    },
    [ongoingCall],
  );

  const handleLambdaError = (errorKey) => {
    switch (errorKey) {
      case LambdaErrorKeys.EmailExists:
        setError('Oops. Diese E-Mail-Adresse wird bereits verwendet.');
        setInvalidFormElements([...invalidFormElements, FormElements.EMAIL]);
        break;
      default:
        setError(errorKey || 'Oops. Etwas ist schief gelaufen.'); // remove errorKey to hide technical errors
    }
  }

  const notifySubmit = () => {
    window.top.postMessage({
      type: EVENT_TYPES.FORM_SUBMIT
    }, '*');
  };

  const isFormValid = (token) => {

    // mandatory input validation
    if (!firstName || !lastName || !company || !email) {
      const invalidElements = [];
      if (!firstName) invalidElements.push(FormElements.FIRST_NAME);
      if (!lastName) invalidElements.push(FormElements.LAST_NAME);
      if (!company) invalidElements.push(FormElements.COMPANY);
      if (!email) invalidElements.push(FormElements.EMAIL);
      setInvalidFormElements(invalidElements);
      setError('Oops. Bitte fülle alle Felder aus.');
      return false;
    }

    // consent checkbox validation
    if (!consent) {
      setInvalidFormElements([FormElements.CONSENT]);
      setError('Oops. Bitte bestätige die Teilnahmebedingungen.');
      return false;
    }

    // email regexp validation
    if (!validateEmail(email)) {
      setInvalidFormElements([FormElements.EMAIL]);
      setError('Oops. Bitte gib eine gültige Email Adresse ein.');
      return false;
    }

    if (!token) {
      setError('Bitte bestätige, dass du kein Roboter bist');
      return false;
    }
    setError(null);
    setInvalidFormElements([]);
    return true;
  }

  const submit = async () => {
    let token = null;
    try {
      token = window.grecaptcha.getResponse();
    } catch (e) {
      // captha did not load
      console.error(e);
    }

    if (!isFormValid(token)) {
      return;
    }

    const payload = {
      company,
      firstname: firstName,
      lastname: lastName,
      email_address: email,
      token,
      warming: false,
    };

    setOngoingCall(true);
    notifySubmit();

    await API.graphql({
      query: submitRegisterForm,
      variables: { payload },
      authMode: 'API_KEY',
    })
    .then((response) => {
      console.warn(JSON.parse(response.data.submitRegisterForm));
      submitCallback(email);
    })
    .catch(response => {
      const error = response?.errors[0];
      try {
        const parsedError = typeof error?.message === 'string' ? JSON.parse(error.message) : null;
        handleLambdaError(parsedError.errorMessage);
      } catch (e) {
        handleLambdaError(error.message);
      }
    })
    .finally(() => {
      try {
        window.grecaptcha.reset();
      } catch (err) {}
      setOngoingCall(false);
    });
  };

  const getClass = (formElement) => {
    return invalidFormElements.includes(formElement) ? 'invalid' : 'valid';
  }

  const getFn = (formElement, value) => {
    const callbacks = {
      [FormElements.FIRST_NAME] : setFirstName,
      [FormElements.LAST_NAME] : setLastName,
      [FormElements.EMAIL] : setEmail,
      [FormElements.COMPANY] : setCompany,
      [FormElements.CONSENT] : setConsent,
    }
    if (value) {
      // clear validation errors & hide error
      setInvalidFormElements(invalidFormElements.filter(el => el !== formElement));
      setError(null);
    }

    callbacks[formElement](value);
  }

  const { FIRST_NAME, LAST_NAME, EMAIL, COMPANY, CONSENT } = FormElements;

  return (
    <>
      {
        ongoingCall &&
        <div className="form-loading">
          <div className="lds-dual-ring"></div>
        </div>
      }
      { !ongoingCall &&
            <div className="form-container">
            <p className="form-description">
              Einfach die folgenden Felder ausfüllen und
              abschicken. Du bekommst dann per Email einen
              Freischaltcode, mit dem du die App für Pflegende
              kostenlos nutzen kannst.
            </p>
            <input className={getClass(FIRST_NAME)} value={firstName} onChange={e => getFn(FIRST_NAME, e.target.value)} placeholder='Vorname'></input>
            <input className={getClass(LAST_NAME)} value={lastName} onChange={e => getFn(LAST_NAME, e.target.value)} placeholder='Nachname'></input>
            <input className={getClass(EMAIL)} value={email} onChange={e => getFn(EMAIL, e.target.value)} placeholder='Email Adresse'></input>
            <div className='autocomplete-container'>
              <AutocompleteComponent className={getClass(COMPANY)} onSelect={companyValue => getFn(COMPANY, companyValue)} />
            </div>
            <div className={`terms-and-conditions ${getClass(CONSENT)}`}>
              <Checkbox
                backAnimationDuration={90}
                tickAnimationDuration={180}
                borderThickness={2}
                className="checkbox"
                size={3}
                checked={!!consent}
                onChange={(checked) => getFn(CONSENT, checked)}
                color={"#F95047"}
              />
              <p>
                Hiermit bestätige ich die Richtigkeit meiner Angaben und
                akzeptiere die <a href="https://www.lifebonus.health/datenschutz" target="_blank" rel="noreferrer">Teilnahmebedingungen</a>
              </p>
            </div>
            <div className="form-errors">
              <p>{error}</p>
            </div>
            <div className="form-actions">
              <div id="recaptcha-container" className="pb-4" data-sitekey={process.env.REACT_APP_CAPTCHA_KEY}></div>
              <button onClick={submit}>Abschicken</button>
            </div>
          </div>
      }
    </>

  );
}

import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import API from '@aws-amplify/api';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { getCompanies } from '../graphql/queries';

const fetchCompanies = async (inputValue) => {
  return API.graphql({
    query: getCompanies,
    variables: { searchQuery: inputValue },
    authMode: 'API_KEY',
  })
  .then(response => JSON.parse(response.data.getCompanies))
  .catch(error => console.log('fetchCompanies error', error));
};

const fetchCompaniesDebounced = AwesomeDebouncePromise(fetchCompanies, 300);

const loadCompanies = inputValue => {
  if (inputValue.length < 1) {
    return Promise.resolve([]);
  }
  return fetchCompaniesDebounced(inputValue);
};

export const AutocompleteComponent = ({ onSelect, className }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const handleChange = (option) => {
    setSelectedOption(option);
    onSelect(option?.name || '');
  }
  const handleMenuOpen = () => {
    setSelectedOption(null);
    onSelect(null);
  };
  const isValid = className === 'valid';
    return (
      <AsyncSelect
        className={className}
        styles={{
          control: () => ({
            borderWidth: isValid ? 1 : 2,
            borderRadius: 0,
            borderStyle: 'solid',
            borderColor: isValid ? 'internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133))' : 'red',
            display: 'flex'
          })
        }}
        cacheOptions
        defaultOptions
        loadOptions={loadCompanies}
        loadingMessage={() => "Wird geladen..."}
        noOptionsMessage={() => "Geben Sie ein, um Optionen anzuzeigen"}
        placeholder="Name deiner Einrichtung"
        getOptionLabel={option => `${option.name}, ${option.city}`}
        onChange={handleChange}
        isClearable={true}
        value={selectedOption}
        onMenuOpen={handleMenuOpen}
      />
    );

}

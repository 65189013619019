import './App.scss';
import { useState, useEffect } from 'react';

import { FormComponent } from './components/FormComponent';
import { SuccessComponent } from './components/SuccessComponent';
import gesa from './resources/gesa.png';

export const EVENT_TYPES = {
  TO_HOST: 'TO_HOST',
  FROM_HOST: 'FROM_HOST',
  ANALYTICS: 'ANALYTICS',
  APP_SIZE: 'APP_SIZE',
  FORM_SUBMIT: 'FORM_SUBMIT',
};

const APP_ID = 'register-form-app';

function App() {
  const [recipientEmail, setRecipientEmail] = useState(null);

  const handleSubmit = (email) => {
    setRecipientEmail(email);
  }

  useEffect(() => {

    window.top.postMessage({
      type: EVENT_TYPES.ANALYTICS,
      eventName: 'user_has_entered_registration_page',
      payload: {},
    }, '*');

    const appHeight = document.getElementById(APP_ID).offsetHeight;

    window.top.postMessage({
      type: EVENT_TYPES.APP_SIZE,
      size: appHeight,
    }, '*');

    // window.addEventListener('message', (e) => {
    //   if (e.data && e.data.type === EVENT_TYPES.FROM_HOST) {
    //     setHostResponse(e.data.payload);
    //   }
    // });

    //  window.top.postMessage({
    //     type: EVENT_TYPES.ANALYTICS,
    //     eventName: 'user_has_submitted_form',
    //     payload: {
    //       success: true,
    //     },
    //   }, '*');

  }, []);

  return (
    <div className="app" id={APP_ID}>
      <header>
        <p className="main-title">So funktioniert's</p>
        <p>Jetzt App kostenlos freischalten.</p>
      </header>
      <section className="content">
        <div className="left-content">
          { recipientEmail && <SuccessComponent email={recipientEmail}></SuccessComponent> }
          { !recipientEmail && <FormComponent submitCallback={handleSubmit}></FormComponent>}
        </div>
        <div className="right-content">
          <img alt="company logo" src={gesa}></img>
        </div>
      </section>
    </div>
  );
}

export default App;

import './SuccessComponent.scss';

export const SuccessComponent = ({ email }) => (
  <div className="success-container">
    <h1>Fertig!</h1>
    <p>
      Wir haben dir soeben per Email einen
      Freischaltcode geschickt, mit dem du die App für
      Pflegende kostenlos nutzen kannst.
    </p>
    <p>Weitere Informationen findest du in der Email: {email}</p>
  </div>
);